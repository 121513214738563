<template>
  <div class="applicants">
    <router-link v-if="isManager" :to="{ name: 'ApplicantsMns2024' }" style="width: 100px">
      <v-btn class="router_btn">&#8592;</v-btn>
    </router-link>
    <div style="display: flex; flex-direction: row; margin-bottom: 25px;">
      <h2 class="applicants__header" style="margin-right: 19px;">Отбор претендентов</h2>
    </div>

    <!--                    applicantDocsDialog                     -->

    <v-dialog
      v-model="applicantDocsDialog"
      width="740px"
    >
      <v-card class="applicantsOrgDocs" :style="dialogHeight">
        <h2 class="applicantsOrgDocs__header">Заявка претендента</h2>
        <img @click="applicantDocsDialog = false" class="dialog__exit" src="@/assets/icons/dialog-exit.png" alt="exit">
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column">
            <p class="document-name" style="font-weight: 500;">{{ docs.fio }}</p>
            <p class="document-file document-file_empty" style="color: #89A0B0; border-bottom: none">{{ docs.info }}</p>
          </div>
        </div>
        <div class="applicantsOrgDocs-div" v-if="!isExpert">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Опись</p>
          </div>
          <img src="@/assets/icons/print.svg" @click="getInventory(docs.rowID)" alt="print" class="document-print">
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"
          ></v-file-input>
          <!--    визуальная заглушка     -->
          <img style="visibility: hidden" src="@/assets/icons/delete-round.svg" alt="delete"
               class="document-delete">
        </div>
        <div class="applicantsOrgDocs-div" v-if="!isExpert">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Лист проверки</p>
          </div>
          <img src="@/assets/icons/print.svg" alt="print" @click="getCheckList(docs.rowID)" class="document-print">
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"
          ></v-file-input>
          <!--    визуальная заглушка     -->
          <img style="visibility: hidden" src="@/assets/icons/delete-round.svg" alt="delete"
               class="document-delete">
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Характеристика-рекомендация претендента (Приложение 1)</p>
            <p
              class="document-file" v-html="character1Computed" v-if="character1Computed !== 'Файл не загружен'"
              @click="getFile(docs.character1.uuid, docs.character1.fileNameOrig, docs.character1.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img v-if="!isExpert" src="@/assets/icons/print.svg" alt="print" @click="getAppendix1(docs.rowID)"
               class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="character1File"
            :style="applicantDocsDisabled && !docs.character1 && !isExpert ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_1', character1File)"
          ></v-file-input>
          <img :style="character1Computed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.character1.uuid, 'FILE_CHARACTER_1')">
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Приложение к характеристике-рекомендации (Приложение 2)</p>
            <p
              class="document-file" v-html="character2Computed" v-if="character2Computed !== 'Файл не загружен'"
              @click="getFile(docs.character2.uuid, docs.character2.fileNameOrig, docs.character2.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img v-if="!isExpert" src="@/assets/icons/print.svg" alt="print" @click="getAppendix2(docs.rowID)"
               class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="character2File"
            :style="applicantDocsDisabled && !docs.character2 && !isExpert ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_2', character2File)"
          ></v-file-input>
          <img :style="character2Computed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.character2.uuid, 'FILE_CHARACTER_2')">
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Сопроводительное письмо</p>
            <p
              class="document-file" v-html="coverLetterComputed" v-if="coverLetterComputed !== 'Файл не загружен'"
              @click="getFile(docs.coverLetter.uuid, docs.coverLetter.fileNameOrig, docs.coverLetter.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img src="@/assets/icons/print.svg" alt="print" style="visibility: hidden" class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="coverLetterFile"
            :style="applicantDocsDisabled && !docs.coverLetter ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_COVER_LETTER', coverLetterFile)"
          ></v-file-input>
          <img :style="coverLetterComputed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.coverLetter.uuid, 'FILE_SOGL')">
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Согласие кандидата на обработку, хранение и передачу ПД</p>
            <p
              class="document-file" v-html="approvalComputed" v-if="approvalComputed !== 'Файл не загружен'"
              @click="getFile(docs.approval.uuid, docs.approval.fileNameOrig, docs.approval.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img src="@/assets/icons/print.svg" alt="print" style="visibility: hidden" class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="approvalFile"
            :style="applicantDocsDisabled && !docs.approval && !isExpert ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOGL', approvalFile)"
          ></v-file-input>
          <img :style="approvalComputed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.approval.uuid, 'FILE_SOGL')">
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">
              Документ, подтверждающий принадлежность к коренным малочисленным народам Севера, Сибири и Дальнего Востока
            </p>
            <p
              class="document-file" v-html="affiliationMnsComputed"
              v-if="affiliationMnsComputed !== 'Файл не загружен'"
              @click="getFile(docs.affiliationMns.uuid, docs.affiliationMns.fileNameOrig, docs.affiliationMns.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img src="@/assets/icons/print.svg" alt="print" style="visibility: hidden" class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="affiliationMnsFile"
            :style="applicantDocsDisabled && !docs.affiliationMns ? '' : 'visibility: hidden'" accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_AFFILATIONS', affiliationMnsFile)"
          ></v-file-input>
          <img :style="affiliationMnsComputed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.affiliationMns.uuid, 'FILE_AFFILATIONS')">
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Выписка из протокола заседания ученого (научного, научно-технического) совета</p>
            <p
              class="document-file" v-html="counsilDecisionComputed"
              v-if="counsilDecisionComputed !== 'Файл не загружен'"
              @click="getFile(docs.councilDecision.uuid, docs.councilDecision.fileNameOrig, docs.councilDecision.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img src="@/assets/icons/print.svg" alt="print" style="visibility: hidden" class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="councilDecisionFile"
            :style="applicantDocsDisabled && !docs.councilDecision && !isExpert ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOVET', councilDecisionFile)"
          ></v-file-input>
          <img :style="counsilDecisionComputed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.councilDecision.uuid, 'FILE_SOVET')">
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Сведения об успеваемости</p>
            <p
              class="document-file" v-html="academicPerformanceComputed"
              v-if="academicPerformanceComputed !== 'Файл не загружен'"
              @click="getFile(docs.academicPerformance.uuid, docs.academicPerformance.fileNameOrig, docs.academicPerformance.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img src="@/assets/icons/print.svg" alt="print" style="visibility: hidden" class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="academicPerformanceFile"
            :style="applicantDocsDisabled && !docs.academicPerformance && !isExpert ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_USPEH', academicPerformanceFile)"
          ></v-file-input>
          <img :style="academicPerformanceComputed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.academicPerformance.uuid, 'FILE_USPEH')">
        </div>
        <div class="applicantsOrgDocs-div" v-if="docs.oldFioRow">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Документ, подтверждающий смену ФИО</p>
            <p
              class="document-file" v-html="oldFioComputed"
              v-if="oldFioComputed !== 'Файл не загружен'"
              @click="getFile(docs.oldFioObject.uuid, docs.oldFioObject.fileNameOrig, docs.oldFioObject.fileExt)">
            </p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <!--заглушка для стилей-->
          <img src="@/assets/icons/print.svg" alt="print" style="visibility: hidden" class="document-print">
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="oldFioFile"
            :style="applicantDocsDisabled && !docs.oldFioObject ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_OLD_FIO', oldFioFile)"
          ></v-file-input>
          <img :style="oldFioComputed === 'Файл не загружен' || !isManager ? 'visibility: hidden' : ''"
               src="@/assets/icons/delete-round.svg" alt="delete" class="document-delete"
               @click="deleteFile(docs.oldFioObject.uuid, 'FILE_OLD_FIO')">
        </div>
        <button class="usual-btn" style="margin: 23px auto 26px 25px;" @click="applicantDocsDialog = false">Закрыть
        </button>
      </v-card>
    </v-dialog>

    <!--                    applicantDocsDialog                     -->

    <div class="applicantsSelectionDiv">
      <div style="margin-right: 25px;">
        <img src="@/assets/icons/applicants-selection-quota.svg" style="margin-right: 10px;">
        Квота: <span style="margin-left: 5px;" v-html="quota"></span></div>
      <div style="margin-right: 25px;">
        <img src="@/assets/icons/applicants-selection-candidates.svg" style="margin-right: 10px;">
        Претенденты: <span style="margin-left: 5px;" v-html="applicantsArray.length"></span></div>
      <div>
        <img src="@/assets/icons/applicants-selection-chosen.svg" style="margin-right: 10px;">
        Отобрано: <span style="margin-left: 5px;">{{ chosenApplicants }}</span></div>
    </div>
    <GridTable
      :data-rows="applicantsArray"
      :headers="tableHeaders.orgMassive"
      :headers-for-rows="tableHeaders.orgMassive"
      :num-cols="tableHeaders.countCells"
      :loading-data="loading"
      :borderTop="'none'"
      id="table"
      style="background: #FFFFFF; box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06); border-radius: 4px;"
    >
      <template #num="{ row }">
        <span :class="'rowOrder-' + row.rowOrder">{{ row.rowOrder }}</span>
      </template>
      <template #docs="{ row }">
        <div class="block__wrapper" @click="applicantDocsDialogOpen(row)">
          <div class="applicantAdmin__docs"></div>
        </div>
      </template>
      <template #fio="{ row }">
        <div style="display: flex; flex-direction: column; margin-left: 16px;" @click="clickOnRow(row)">
          <span>{{ row.mainLastName }}</span>
          <span>{{ row.mainFirstName }}</span>
          <span>{{ row.mainSecondName }}</span>
        </div>
      </template>
      <template #orgInfo="{ row }">
        <span>{{ row.mainOrg.owner.name }}</span>
        <span class="orgId">{{ row.mainOrg.orgFounder.name }}</span>
      </template>
      <template #spec="{ row }">
        <div style="display:flex; flex-direction: column">
          <span style="font-weight: 600;">{{ row.mainSpec.kodSpec }}</span>
          <span>{{ row.mainSpec.name }}</span>
        </div>
      </template>
      <template #education="{ row }">
        <div style="display: flex; flex-direction: column;">
          <span>{{ `${row.mainFo.name} форма` }}</span>
          <span>{{ `${row.mainCourse} курс/год обучения` }}</span>
        </div>
      </template>
      <template #marks="{ row }">
        <div>
          <p v-if="row.ballScoreMethod">{{ row.ballScoreMethod }}<!--<span>методика</span>--></p>
        </div>
      </template>
      <template #score="{ row }">
        <div>
          <p v-if="row.methodAndExpertTotalBallMethod && (!isExpert || isManager)">{{
              row.methodAndExpertTotalBallMethod
            }}<span>методика</span></p>
          <p v-if="row.methodAndExpertTotalBallExpert">{{ row.methodAndExpertTotalBallExpert }}<span>комиссия</span></p>
        </div>
      </template>
      <template #konvert="{ row }">
        <div v-if="row.konvertConvert" style="display: flex; flex-direction: column">
          <div class="d-flex flex-row">
            <img src="@/assets/icons/konvert.svg" style="margin-right: 10px; align-self: baseline;">
            <div style="display: flex; flex-direction: column">
              <span>{{ row.konvertConvert }}</span>
              <span class="konvertDate" v-if="row.konvertConvertDate">
              {{ dateFormat(row.konvertConvertDate.slice(0, 10)) }}
            </span>
            </div>
          </div>
          <v-btn
            v-if="(row.methodAndExpertCommissionPass === true || row.methodAndExpertCommissionPass === false) && (!isExpert || isManager)"
            class="router_btn" style="margin-top: 12px;" @click="reset(row)">Отозвать
          </v-btn>
          <div v-else-if="!isExpert || isManager"
               style="display: flex; flex-direction: row; margin-top: 12px; justify-content: center;">
            <div @click="approve(row)" class="acceptApplicant"></div>
            <div @click="deny(row)" class="cancelKonvert"></div>
          </div>
        </div>
      </template>
      <template #noScanList="{ row }">
        <div v-if="row.noScanBlockList">
          <span v-if="row.noScanBlockList.length > 0">Количество</span>
          <div v-for="(item, j) in row.noScanBlockList" :key="j"
               style="display:flex; flex-direction: row; margin-top: 4px;">
            <img src="@/assets/icons/check-false.svg" style="margin-right: 10px;">
            <span>{{ item }}</span>
          </div>
        </div>
      </template>
    </GridTable>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import {GridTable} from "@frontenddevelopers/ined-components/src/lib";
import ApplicantsSelectionMnsJson
  from "@/2024/components/applicants/applicantsSelection/mns/ApplicantsSelectionMnsJson";

export default {
  name: "ApplicantsSelectionMns",
  components: {GridTable},
  data() {
    return {
      tableHeaders: [],
      applicantsArray: [],
      applicantDocsDialog: false,
      loading: false,
      quota: null,
      character1File: null,
      character2File: null,
      approvalFile: null,
      councilDecisionFile: null,
      academicPerformanceFile: null,
      coverLetterFile: null,
      affiliationMnsFile: null,
      oldFioFile: null,
      docs: {
        row: {konvertConvert: null,},
        rowID: null,
        fio: null,
        info: null,
        oldFioRow: false,
        approval: {},
        character1: {},
        character2: {},
        councilDecision: {},
        academicPerformance: {},
        oldFioObject: {},
        coverLetter: {},
        affiliationMns: {},
      }
    }
  },

  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getFakeUser: 'getFakeUser',
      isManager: 'isManager',
      isExpert: 'isExpert'
    }),

    chosenApplicants() {
      return this.applicantsArray.filter(el => el.methodAndExpertCommissionPass === true).length
    },

    applicantDocsDisabled() {
      if (this.docs.row?.blockFlBlock === true && this.docs.row?.adminAgreePatchAdminTbl5Agree === null) {
        return true
      } else {
        return false
      }
    },

    approvalComputed() {
      if (this.docs.approval) {
        return `${this.docs.approval.fileNameOrig}.${this.docs.approval.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    character1Computed() {
      if (this.docs.character1) {
        return `${this.docs.character1.fileNameOrig}.${this.docs.character1.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    character2Computed() {
      if (this.docs.character2) {
        return `${this.docs.character2.fileNameOrig}.${this.docs.character2.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    counsilDecisionComputed() {
      if (this.docs.councilDecision) {
        return `${this.docs.councilDecision.fileNameOrig}.${this.docs.councilDecision.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    academicPerformanceComputed() {
      if (this.docs.academicPerformance) {
        return `${this.docs.academicPerformance.fileNameOrig}.${this.docs.academicPerformance.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    coverLetterComputed() {
      if (this.docs.coverLetter) {
        return `${this.docs.coverLetter.fileNameOrig}.${this.docs.coverLetter.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    affiliationMnsComputed() {
      if (this.docs.affiliationMns) {
        return `${this.docs.affiliationMns.fileNameOrig}.${this.docs.affiliationMns.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    oldFioComputed() {
      if (this.docs.oldFioObject) {
        return `${this.docs.oldFioObject.fileNameOrig}.${this.docs.oldFioObject.fileExt}`
      } else {
        return 'Файл не загружен'
      }
    },

    dialogHeight() {
      if (this.isExpert && this.docs.oldFioRow) {
        return 'min-height: 820px';
      } else if (this.isExpert) {
        return 'min-height: 750px';
      } else if (this.docs.oldFioRow) {
        return 'min-height: 930px';
      } else {
        return 'min-height: 860px';
      }
    },
  },

  created() {
    this.createdFunc();
  },

  methods: {
    ...mapActions('keycloak', {initFakeUser: 'initFakeUser'}),

    async createdFunc() {
      await this.getAllCommission();
      this.tableHeaders = ApplicantsSelectionMnsJson(this);
      if (this.$route.params.rowOrder) {
        await this.tableScrollIntoView();
      }
    },

    tableScrollIntoView() {
      setTimeout(() => {
        let table = document.getElementById("table");
        let elem = table.getElementsByClassName(`rowOrder-` + this.$route.params.rowOrder)[0];
        elem.scrollIntoView();
      }, 2000)
    },

    async getAllCommission() {
      this.loading = true;
      let uri = `/api/2024/mns/getAllCommission`;
      try {
        const res = await axios.get(uri, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.applicantsArray = res.data;
          for (let a = 0; a < this.applicantsArray.length; a++) {
            this.applicantsArray[a].rowOrder = a + 1;
            if (this.applicantsArray[a].adminAgreePatchAdminTbl5Agree === true && this.applicantsArray[a].methodAndExpertCommissionPass === null) {
              this.applicantsArray[a].statusBackgroundColor = '#add8e6';
            } else if (this.applicantsArray[a].methodAndExpertCommissionPass === true) {
              this.applicantsArray[a].statusBackgroundColor = '#66FF66';
            } else if (this.applicantsArray[a].methodAndExpertCommissionPass === false) {
              this.applicantsArray[a].statusBackgroundColor = '#FF6666';
            } else {
              this.applicantsArray[a].statusBackgroundColor = 'none';
            }
          }
          this.loading = false;
          await this.getQuota();
          console.log('getAllCommission успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getQuota() {
      this.loading = true;
      let uri = `/api/2024/mns/getQuota`;
      try {
        const res = await axios.get(uri, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.quota = res.data;
          this.loading = false;
          console.log('getQuota успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    dateFormat(arg) {
      let x = arg.replace(/-/g, ".");
      let years = x.slice(0, 4);
      let days = x.slice(-2);
      let months = x.slice(4, -2);
      return `${days}${months}${years}`
    },

    async approve(row) {
      try {
        const res = await axios.put(`/api/2024/mns/commissionApprove?rowId=${row.id}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.applicantsArray[row.rowOrder - 1].statusBackgroundColor = '#66FF66';
          this.applicantsArray[row.rowOrder - 1].methodAndExpertCommissionPass = true;
          this.applicantsArray.push({});
          this.applicantsArray.pop();
          console.log('approve успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async deny(row) {
      try {
        const res = await axios.put(`/api/2024/mns/commissionDeny?rowId=${row.id}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.applicantsArray[row.rowOrder - 1].statusBackgroundColor = '#FF6666';
          this.applicantsArray[row.rowOrder - 1].methodAndExpertCommissionPass = false;
          this.applicantsArray.push({});
          this.applicantsArray.pop();
          console.log('deny успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async reset(row) {
      try {
        const res = await axios.put(`/api/2024/mns/commissionReset?rowId=${row.id}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.applicantsArray[row.rowOrder - 1].statusBackgroundColor = '#add8e6';
          this.applicantsArray[row.rowOrder - 1].methodAndExpertCommissionPass = null;
          this.applicantsArray.push({});
          this.applicantsArray.pop();
          console.log('reset успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async clickOnRow(e) {
      await this.initFakeUser({id: e.mainOrg.owner.id, name: e.mainOrg.owner.name, admin: true});
      await this.$router.push({
        name: 'ApplicantFormMns2024',
        params: {rowID: `${e.id}`, routeName: this.$route.name, rowOrder: e.rowOrder}
      });
    },

    async filePost(fileType, file) {
      const formData = new FormData();
      formData.append('fileType', fileType);
      formData.append('file', file);
      formData.append('rowId', this.docs.rowID);

      if (file.type === "application/pdf") {
        try {
          const res = await axios.post(`/api/2024/mns/upload/${fileType}`, formData,
            {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
          if (res.data) {

            if (fileType === 'FILE_CHARACTER_1') {
              this.docs.character1 = res.data.character1;
            } else if (fileType === 'FILE_CHARACTER_2') {
              this.docs.character2 = res.data.character2;
            } else if (fileType === 'FILE_SOGL') {
              this.docs.approval = res.data.approval;
            } else if (fileType === 'FILE_SOVET') {
              this.docs.councilDecision = res.data.councilDecision;
            } else if (fileType === 'FILE_COVER_LETTER') {
              this.docs.coverLetter = res.data.coverLetter;
            } else if (fileType === 'FILE_AFFILATIONS') {
              this.docs.affiliationMns = res.data.affiliationMns;
            } else if (fileType === 'FILE_USPEH') {
              this.docs.academicPerformance = res.data.academicPerformance;
            } else if (fileType === 'FILE_OLD_FIO') {
              this.docs.oldFioObject = res.data.oldFio
            }

            await this.getAllCommission();
            this.loading = false;
            this.character1File = null;
            this.character2File = null;
            this.approvalFile = null;
            this.councilDecisionFile = null;
            this.academicPerformanceFile = null;
            this.affiliationMnsFile = null;
            this.coverLetterFile = null;
            this.oldFioFile = null;
            console.log('filePost успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else {
        alert(`файл должен соответствовать формату pdf`)
      }
    },

    async getFile(id, name, ext) {
      let uri = `/api/2024/file/scan/download?file=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },

    async deleteFile(ID, fileType) { // fileType - костыль на визуальное удаление файлов налету
      this.loading = true;
      let uri = `/api/2024/file/scan/delete?fileId=${ID}`;
      try {
        const res = await axios.delete(uri, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {

          if (fileType === 'FILE_CHARACTER_1') {
            this.docs.character1 = null;
          } else if (fileType === 'FILE_CHARACTER_2') {
            this.docs.character2 = null;
          } else if (fileType === 'FILE_SOGL') {
            this.docs.approval = null;
          } else if (fileType === 'FILE_SOVET') {
            this.docs.councilDecision = null;
          } else if (fileType === 'FILE_USPEH') {
            this.docs.academicPerformance = null;
          } else if (fileType === 'FILE_COVER_LETTER') {
            this.docs.coverLetter = null
          } else if (fileType === 'FILE_AFFILATIONS') {
            this.docs.affiliationMns = null
          } else if (fileType === 'FILE_OLD_FIO') {
            this.docs.oldFioObject = null;
          }

          await this.getAllCommission();
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getInventory(ID) {
      let uri = `/api/2024/reports/mns/inventory/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf')
    },

    async getCheckList(ID) { // print
      let uri = `/api/2024/reports/mns/check_list/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf')
    },

    async getAppendix1(ID) { // print
      let uri = `/api/2024/reports/mns/appendix_1/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf')
    },

    async getAppendix2(ID) { // print
      let uri = `/api/2024/reports/mns/appendix_2/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf')
    },

    async applicantDocsDialogOpen(row) {
      this.docs.row = row;

      if (row.mainEdit2022OldFirstName || row.mainEdit2022OldLastName || row.mainEdit2022OldSecondName) {
        this.docs.oldFioRow = true;
      } else {
        this.docs.oldFioRow = false;
      }

      this.character1File = null;
      this.character2File = null;
      this.approvalFile = null;
      this.councilDecisionFile = null;
      this.academicPerformanceFile = null;
      this.affiliationMnsFile = null;
      this.coverLetterFile = null;
      this.oldFioFile = null;

      this.docs.rowID = row.id;
      this.docs.approval = row.approval;
      this.docs.character1 = row.character1;
      this.docs.character2 = row.character2;
      this.docs.councilDecision = row.councilDecision;
      this.docs.academicPerformance = row.academicPerformance;
      this.docs.coverLetter = row.coverLetter;
      this.docs.affiliationMns = row.affiliationMns;
      this.docs.oldFioObject = row.oldFio;
      this.docs.fio = `${row.mainLastName} ${row.mainFirstName} ${row.mainSecondName}`;
      this.docs.info = `Студент • ${row.mainFo.name} • ${row.mainCourse} курс/год обучения`;
      this.applicantDocsDialog = true;
      console.log('applicantDocsDialogOpen');
    },
  }
}
</script>

<style lang="scss">
</style>